// --------------------------------------------------------------------------
// Modals
// --------------------------------------------------------------------------

.mobile-menu {
	@include breakpointMax(lg) {
		position: fixed;
		background: $gray2;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		overflow-y: auto;
		z-index: 200;
		padding: 35px 25px;

		text-align: center;
		display: flex;
		flex-direction: column;
		align-items: center;

		color: $white;

		opacity: 0;
		visibility: hidden;
		transition: .25s;

		&.active {
			opacity: 1;
			visibility: visible;
		}
		&__list {
			list-style: none;
		}
		&__logo {
			line-height: 0;
			width: 116px;
		}
		hr {
			border-width: 1px;
			border-color: rgba($white, .2);
			margin: 20px 0 25px;
			width: 100%;
		}
		.link {
			color: $white;
			font-size: 17px;
			display: inline-block;
			margin-bottom: 20px;
		}
		.contacts {
			margin-top: 40px;
		}
		.link-call {
			margin-bottom: 45px;
		}
		.list-socials {
			margin-top: 45px;
		}

		.btn {
			flex-shrink: 0;
		}
	}
	
}

.modal-callback {
	background: $blue;
	max-width: 100%;
	padding: 40px 20px 30px;

	display: flex;
	flex-direction: column;

	color: $white;

	@include breakpoint(sm) {
		max-width: 500px;
		margin-top: 20px;
		margin-bottom: 20px;
		height: auto;
		border-radius: 10px;
	}
	.fancybox-close-small {
		display: none;
	}
	&__section {
		position: relative;
		margin-bottom: 22px;
		&-input {
			position: relative;
		}
		&-icon {
			position: absolute;
			left: 10px;
			top: 50%;
			transform: translateY(-50%);
			line-height: 0;
			svg {
				fill: $blue;
			}
		}
	}

	&__info {
		text-align: center;
		font-size: 14px;
		line-height: 17px;
		text-align: center;
		color: rgba($white, .7);
		margin-top: 20px;
		p {
			margin-bottom: 14px;
			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	&__policy {
		display: none;

		background: $white;
		border-radius: 12px;
		padding: 15px;
		font-size: 14px;
		line-height: 17px;
		color: rgba($black, .6);
		max-height: 232px;

		margin-top: 25px;

		overflow-y: auto;

		p {
			margin-bottom: 14px;
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
	
}


.modal-close {
	position: absolute;
	left: 20px;
	top: 20px;
	svg {
		stroke: $white;
	}
}
