//- --------------------------------------------------------------------------
//- Form
//- --------------------------------------------------------------------------


.is-error {
    border-color: #8A0502;
}

.is-success {
    border-color: #159E24;
}

// -----

input, button, select, textarea {
	font-family: inherit;
	font-size: inherit;
	line-height: inherit;
	-webkit-appearance: none;
	border-radius: 0;
	background-color: transparent;
}

button {
	cursor: pointer;
	border: 0;
	outline: none;
}

input {
	vertical-align: middle;
	outline: none;
	border: 0;
	&[type="submit"], &[type="button"] {
		cursor: pointer;
		-webkit-appearance: none;
	}
}

textarea {
	outline: none;
	resize: none;
	overflow: auto;
}

:invalid {
	box-shadow: none;
}

//

.ui-label {
	font-size: 17px;
	line-height: 20px;
	display: block;
	margin-bottom: 2px;
}

// -----

.ui-input {
	display: block;
	width: 100%;
	background: #fff;
	height: 62px;
	border: 1px solid transparent;
	font-size: 17px;
	padding: 5px 15px;
	font-family: $fontBase;

	background: $white;
	border: 1px solid $blue;
	border-radius: 4px;
	
	-webkit-appearance: none;
	transition: 0.25s;
	
	&:focus {
		border-color: darken($blue, 15%);
	}

	&.is-error {
		background: $pink;
		border-color: $red;
		border-width: 2px;
	}
}


.input-callback {
	display: block;
	width: 100%;
	background: $white;
	height: 52px;
	border: 1px solid transparent;
	font-size: 18px;
	font-weight: 500;
	padding: 5px 20px;
	font-family: $fontBase;

	background: $white;
	border-radius: 4px;

	color: $blue;
	
	-webkit-appearance: none;
	transition: 0.25s;

	&--phone {
		padding-left: 55px;
	}
	
	&:focus {
		border-color: darken($blue, 15%);
	}

	&.is-error {
		background: $pink;
		border-color: $red;
		border-width: 2px;
	}

	&::placeholder {
		color: $gray3;
	}
}

.label-callback {
	display: block;
	font-weight: 500;
	font-size: 15px;
	line-height: 18px;
	color: rgba($white,.6);
	margin-bottom: 5px;
}


//

.ui-checkbox {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 20px;
	&__icon {
		flex-shrink: 0;
		margin-right: 15px;
		width: 24px;
		height: 24px;
		display: flex;
		align-items: center;
		justify-content: center;
		border: 2px solid $white;
		border-radius: 100%;

		cursor: pointer;
		svg {
			transition: .25s;
			opacity: 0;
			visibility: hidden;
			width: 12px;
			height: 12px;
		}
	}
	&__text {
		font-size: 17px;
		line-height: 20px;
		a {
			text-decoration: none;
			font-weight: bold;
			color: $white;
			border-bottom: 1px solid rgba($white, .5);
			transition: .25s;
			&:hover {
				border-color: transparent;
			}
		}
	}
	input{
		&:checked {
			&+.ui-checkbox__icon {
				svg {
					opacity: 1;
					visibility: visible;
				}
			}
		}
	}
}



