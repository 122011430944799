// --------------------------------------------------------------------------
// States
// --------------------------------------------------------------------------

.d-flex {
	display: flex;
}

//

.noselect {
    user-select: none;
}

// ------

.text-center {
	text-align: center;
}

.text-left {
	text-align: left;
}

.text-right {
	text-align: right;
}

.text-nowrap {
	white-space: nowrap;
}


.text {
	&-lg {
		&-left {
			@include breakpoint(lg) {
				text-align: left;
			}
		}
		&-right {
			@include breakpoint(lg) {
				text-align: right;
			}
		}
	}
}

// ------

.text-green {
	color: #159E24;
}

.text-red {
	color: #8A0502;
}

.text-yellow {
	color: #83653D;
}

.text-grey {
	color: #616781;
}

// ------

.row-offset-10 {
	margin: 0 -5px;
	& > [class^="col-"] {
		padding: 0 5px;
	}
}


// -----

.is-hidden {
	display: none;
}

.is-sm-visible {
	@include breakpointMax(sm) {
		display: none;
	}
}

.is-md-visible {
	@include breakpointMax(md) {
		display: none;
	}
}

.is-lg-visible {
	@include breakpointMax(lg) {
		display: none;
	}
}

.is-xl-visible {
	@include breakpointMax(xl) {
		display: none;
	}
}

// -----

.is-xs-hidden {
	@include breakpoint(sm) {
		display: none;
	}
}

.is-sm-hidden {
	@include breakpoint(sm) {
		display: none;
	}
}

.is-md-hidden {
	@include breakpoint(md) {
		display: none;
	}
}

.is-lg-hidden {
	@include breakpoint(lg) {
		display: none;
	}
}

.is-xl-hidden {
	@include breakpoint(xl) {
		display: none;
	}
}


// -----

.is-menu-active {
	overflow: hidden;
}

// -----

.pixelated {
	image-rendering:optimizeSpeed;
	image-rendering:-moz-crisp-edges;
	image-rendering:-o-crisp-edges;
	image-rendering:-webkit-optimize-contrast;
	image-rendering:optimize-contrast;
	image-rendering:crisp-edges;
}

//

.d-none {
	display: none;
}

//

.line-height-none {
	line-height: 0;
}

//

.is-modal-active {
	overflow: hidden;
}

//

.d-none {
	display: none;
}




