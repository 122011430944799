// --------------------------------------------------------------------------
// Header
// --------------------------------------------------------------------------

.header {
	&__top {
		background: $gray;
		padding-top: 3px;
		padding-bottom: 3px;
		@include breakpoint(lg) {
			padding-top: 13px;
			padding-bottom: 12px;
		}

	}
	&__main {
		padding-top: 53px;
	}
}

.header-menu {
	list-style: none;
	display: flex;
	&>li {
		margin-right: 50px;
		&>a {
			color: $white;
			font-size: 17px;
			line-height: 24px;
		}
	}
}

.link-sign-in {
	color: $white;
	font-size: 17px;
	font-weight: 500;
	svg {
		margin-right: 8px;
	}
}

.logo-text {
	text-transform: uppercase;
	font-size: 30px;
	line-height: 36px;
	color: $gray;
	margin-top: 12px;
}


.logo-wrapper {
	width: 116px;
}

.mobile-logo {
	width: 62px;
}


