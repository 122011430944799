// --------------------------------------------------------------------------
// Elements
// --------------------------------------------------------------------------

.contacts {
	display: flex;
	margin-bottom: 4px;
	&__icon {
		width: 24px;
		flex-shrink: 0;
		margin-right: 8px;
		margin-top: 4px;
		svg {
			fill: $lime;
		}
	}

	a {
		text-decoration: none;
		color: $black;
		font-weight: 500;
		font-size: 22px;
		margin-bottom: 18px;
		display: inline-flex;
	}

	&--footer {
		padding-top: 5px;
		.contacts {
			&__icon {
				margin-top: 0;
				svg {
					fill: $white;
				}
			}
		}
		a {
			margin-bottom: 19px;
			color: $white;
		}
	}
}

//
.decor {
	position: absolute;
}

//

.text-section {
	display: flex;
	margin-bottom: 22px;

	flex-wrap: wrap;
	@include breakpoint(lg) {
		flex-wrap: nowrap;
	}
}

//

.section-text {
	@include breakpoint(lg) {
		padding-top: 65px;
	}
	.heading {
		margin-bottom: 38px;
	}
}

//

.link-form {
	text-decoration: none;
	color: rgba($black, .5);
}

//

.form-wrapper {
	max-width: 400px;
	margin-left: auto;
	margin-right: auto;
	padding-top: 40px;

}

//

.content-col {
	@include breakpoint(lg) {
		padding-top: 40px;
	}
	.form-title {
		margin-top: 15px;
	}
}

//

.form-bottom-link {
	margin-top: 30px;
}

//

.form-section {
	margin-bottom: 30px;
}

//

.list-socials {
	list-style: none;
	display: flex;
	&>li {
		&:not(:last-child) {
			margin-right: 30px;
			@include breakpoint(lg) {
				margin-right: 10px;
			}
		}
	}
}

.link-call {
	color: $white;
}

//

.decor-container {
	pointer-events: none;
	@include breakpoint(lg) {
		position: absolute;
		left: 50%;
		top: -50px;
		transform: translateX(-50%);
		height: 100%;
		width: 100%;
		width: 1440px;
	}
	img {
		z-index: 10;
		position: absolute;
		pointer-events: none;

		&.decor-7 {
			z-index: 11;
		}
	}
}

.decor {
	&-13 {
		right: 25px;
		top: 70px;
		&--page-text-double {
			top: 3535px;
		}
	}
	&-14 {
		right: 16px;
		top: 95px;
		&--page-text-double {
			top: 3565px;
		}
	}
	&-25 {
		top: 240px;
		right: 100px;
		transform: scale(.6);
		@include breakpoint(lg) {
			right: 113px;
			top: 385px;
			transform: none;
		}
	}
	&-26 {
		right: 292px;
		top: 820px;
		opacity: .5;
	}
	&-27 {
		right: 25px;
		top: 1000px;
		&--page-text {
			top: 900px;
		}
		&--page-text-double {
			top: 4370px;
		}
	}
	&-19 {
		left: -7px;
		top: 695px;
		&--page-text-double {
			top: 3900px;
		}
	}
	&-16 {
		left: -130px;
		top: 735px;
	}
	&-28 {
		right: 50px;
		top: 1100px;
	}
	&-29 {
		right: 25px;
		top: 1305px;
	}
	&-30 {
		left: -7px;
		top: 1515px;
		&--page-text {
			top: 1283px;
		}
		&--page-text-double {
			top: 4750px;
		}
	}
	&-31 {
		right: 100px;
		top: 1550px;
		&--page-text {
			top: 1320px;
		}
		&--page-text-double {
			top: 4785px;
		}
	}
	&-32 {
		right: 110px;
		top: 1590px;
		&--page-text {
			top: 1365px;
		}
		&--page-text-double {
			top: 4830px;
		}
	}
	&-20 {
		left: 65px;
		top: 1607px;
		&--page-text {
			top: 1375px;
		}
		&--page-text-double {
			top: 4845px;
		}
	}
	&-8 {
		left: -120px;
		top: 1660px;
	}
	&-22 {
		left: -28px;
		top: 2015px;
		&--page-text {
			top: 1783px;
		}
		&--page-text-double {
			top: 5252px;
		}
	}
	&-12 {
		right: -200px;
		top: 2170px;
	}
	&-33 {
		left: 135px;
		top: 2622px;
	}
	&-34 {
		left: 180px;
		top: 2632px;
	}
	&-18 {
		right: -55px;
		top: 2640px;
	}
	&-10 {
		left: 40px;
		top: 2895px;
	}
	&-6 {
		right: 36px;
		top: 3080px;
	}
	&-11 {
		right: -100px;
		top: 3560px;
	}
	&-35 {
		left: 240px;
		top: 3655px;
	}
	&-36 {
		left: 248px;
		top: 3700px;
	}
	&-37 {
		left: -196px;
		top: 3800px;
	}
	&-38 {
		right: 166px;
		top: 4490px;
	}
	&-39 {
		top: -90px;
		right: -30px;
		width: 175px;
		@include breakpoint(lg) {
			right: -205px;
			top: -220px;
			width: auto;
		}
	}
	&-40 {
		left: 66px;
		top: 4890px;
	}
	&-41 {
		left: -25px;
		top: 5000px;
	}
	&-42 {
		right: -15px;
		top: 5265px;
	}
	&-43 {
		left: -10px;
		top: 5475px;
	}
	&-44 {
		right: 25px;
		top: 5875px;
	}
	&-21 {
		right: 50px;
		top: 5950px;
	}
	&-17 {
		left: -150px;
		top: 4840px;
	}
	&-7 {
		width: 532px;
		z-index: 2;
		max-width: none;
		max-height: none;
		right: -100px;
		top: 115px;
		@include breakpoint(lg) {
			right: -170px;
			top: 195px;
			width: auto;
		}
	}
	&-15 {
		
		width: 170px;
		max-width: none;
		max-height: none;
		top: 30px;
		right: -15px;
		@include breakpoint(lg) {
			right: -90px;
			top: 25px;
			width: auto;
		}
	}
	&-23 {
		right: -28px;
		top: 622px;
		z-index: 2;
	}


	&-45 {
		top: 1940px;
		right: -210px;

		&--page-text-double {
			top: 5410px;
		}
	}
	&-46 {
		top: 4155px;
		left: -7px;
	}
}

