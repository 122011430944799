// --------------------------------------------------------------------------
// Fonts
// --------------------------------------------------------------------------

@font-face {
    font-family: 'Rubik';
    src: url('../fonts/Rubik-Bold.eot');
    src: local('Rubik Bold'), local('Rubik-Bold'),
        url('../fonts/Rubik-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Rubik-Bold.woff2') format('woff2'),
        url('../fonts/Rubik-Bold.woff') format('woff'),
        url('../fonts/Rubik-Bold.ttf') format('truetype'),
        url('../fonts/Rubik-Bold.svg#Rubik-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Rubik';
    src: url('../fonts/Rubik-Regular.eot');
    src: local('Rubik Regular'), local('Rubik-Regular'),
        url('../fonts/Rubik-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Rubik-Regular.woff2') format('woff2'),
        url('../fonts/Rubik-Regular.woff') format('woff'),
        url('../fonts/Rubik-Regular.ttf') format('truetype'),
        url('../fonts/Rubik-Regular.svg#Rubik-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Rubik';
    src: url('../fonts/Rubik-Medium.eot');
    src: local('Rubik Medium'), local('Rubik-Medium'),
        url('../fonts/Rubik-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Rubik-Medium.woff2') format('woff2'),
        url('../fonts/Rubik-Medium.woff') format('woff'),
        url('../fonts/Rubik-Medium.ttf') format('truetype'),
        url('../fonts/Rubik-Medium.svg#Rubik-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Manrope';
    src: url('../fonts/Manrope-Bold.eot');
    src: local('Manrope Bold'), local('Manrope-Bold'),
        url('../fonts/Manrope-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Manrope-Bold.woff2') format('woff2'),
        url('../fonts/Manrope-Bold.woff') format('woff'),
        url('../fonts/Manrope-Bold.ttf') format('truetype'),
        url('../fonts/Manrope-Bold.svg#Manrope-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

