// --------------------------------------------------------------------------
// Footer
// --------------------------------------------------------------------------

.footer {
	background: $gray;
	color: $white;

	padding-top: 57px;
	padding-bottom: 40px;

	hr {
		margin-top: 25px;
		border-width: 1px;
		border-color: rgba($white, .2);
		@include breakpoint(lg) {
			margin: 20px 0 25px;
		}
	}

	.list-socials {
		margin-top: 37px;
		margin-bottom: 30px;
		@include breakpoint(lg) {
			margin-bottom: 0;
		}
	}
}

.footer-menu {
	list-style: none;
	display: flex;
	margin-top: 17px;
	margin-bottom: 30px;
	@include breakpoint(lg) {
		margin-bottom: 0;
	}
	&>li {
		&:not(:last-child) {
			margin-right: 20px;
			@include breakpoint(lg) {
				margin-right: 50px;
			}
		}
		&>a {
			font-size: 16px;
			color: $white;
			@include breakpoint(lg) {
				font-size: 17px;
			}
		}
	}
}

.footer-links {
	list-style: none;
	margin-bottom: 32px;
	&>li {
		&:not(:last-child) {
			margin-bottom: 10px;
		}
		&>a {
			color: $white;
			font-size: 16px;
			line-height: 19px;
			@include breakpoint(lg) {
				color: rgba($white, .5);
			}
		}
	}
}

.copyright {
	font-size: 14px;
	line-height: 17px;
	color: rgba($white, .5);
}
