// --------------------------------------------------------------------------
// Main
// --------------------------------------------------------------------------

.main {
	flex: 1 0 auto;
	width: 100%;

	position: relative;
	overflow: hidden;
}
