// --------------------------------------------------------------------------
// Sections
// --------------------------------------------------------------------------


.section-main {
	padding-top: 25px;
	padding-bottom: 50px;
	padding-bottom: 100px;
	&--with-title {
		.section-main {
			&__content {
			}
			&__img {
				@include breakpoint(lg) {
					margin-top: 65px;
					
				}
			}
		}
	}
	.container {
		z-index: 20;
	}
	@include breakpoint(lg) {
		padding-top: 50px;
		padding-bottom: 100px;
		
		background-image: url(../images/bg1.png);
		background-repeat: no-repeat;
		background-position: center 0;
		background-size: cover;
		
	}
	@include breakpoint(xl) {
		min-height: 840px;
	}
	&__content {
		@include breakpoint(lg) {
			padding-top: 60px;
		}

		&-col {
			position: relative;
			z-index: 10;
		}
	}
	&__img {
		display: flex;
		justify-content: flex-end;
		position: relative;
		padding-top: 45px;
		@include breakpoint(lg) {
			padding-top: 82px;
		}
		&-car {
			z-index: 25;
			position: relative;
			max-width: 290px;
			margin-right: -30px;
			align-self: center;
			@include breakpoint(lg) {
				max-width: 491px;
				margin-right: -105px;
			}
		}
	}
}

.statistic {
	
	@include breakpoint(lg) {
		border: 1px solid rgba(0, 0, 0, 0.12);
		box-shadow: 0px 64px 64px -48px rgba(31, 47, 70, 0.12);
		border-radius: 12px;
		background: $white;
		max-width: 600px;
		padding: 20px 20px;
		
	}

	&__title {
		color: $blue;
		font-size: 20px;
		line-height: 24px;
		margin-bottom: 18px;
	}
}

//


.section-about {
	
	padding-top: 50px;
	padding-bottom: 0px;

	position: relative;

	@include breakpoint(lg) {
			&:after {
				content: "";
				position: absolute;
				left: 50%;
				transform: translateX(-50%);
				top: 190px;
				width: 1102px;
				height: 3534px;
				background: url(../images/line.svg) no-repeat;
			}
		}
	
	@include breakpoint(lg) {
		background-image: url(../images/bg1.png);
		background-repeat: repeat-y;
		background-position: center 0;
		background-size: 100% auto;
		padding-bottom: 120px;
		padding-top: 90px;
	}

	&__heading {
		margin-bottom: 25px;
		@include breakpoint(lg) {
			margin-bottom: 75px;
		}
	}
}

//


.about-company {
	padding-top: 65px;
	padding-bottom: 10px;
	position: relative;
	@include breakpoint(lg) {
		padding-bottom: 40px;
	}

	p {
		font-size: 17px;
		line-height: 20px;
	}

	.row {
		align-items: center;
	}

	.heading {
		margin-bottom: 30px;
	}

	.heading-about {
		margin-bottom: 20px;
	}

	&__img {
		@include breakpointMax(md) {
			margin-left: -8px;
			margin-right: -8px;
			
		}
		@include breakpointMax(lg) {
			height: 240px;
			margin-left: -15px;
			margin-right: -15px;
			margin-bottom: 20px;
		}

		&.col {
			flex-basis: auto;
			@include breakpoint(xl) {
				padding-left: 75px;
			}
		}
		img {
			@include breakpointMax(lg) {
				object-fit: cover;
				width: 100%;
				height: 100%;
			}
			@include breakpoint(xl) {
				max-width: none;
			}
		}
	}


}

//

.section-testiominals {
	padding-top: 35px;
	padding-bottom: 40px;
	background-image: url(../images/bg1.png);
	background-repeat: repeat-y;
	background-position: center 0;
	background-size: 100% auto;
	
	@include breakpoint(lg) {
		padding-top: 70px;
		padding-bottom: 80px;
	}

	.heading {
		margin-bottom: 24px;
	}
	.subtitle {
		margin-bottom: 30px;

		@include breakpointMax(lg) {
			font-size: 25px;
			line-height: 30px;
		}
		@include breakpoint(lg) {
			margin-bottom: 50px;
		}
	}
}


