// --------------------------------------------------------------------------
// Item about
// --------------------------------------------------------------------------

.item-about {
	@include breakpointMax(lg) {
		padding-top: 40px;
		padding-bottom: 40px;
		&:nth-child(odd) {
			background-image: url(../images/bg1.png);
			background-repeat: repeat-y;
			background-position: center 0;
			background-size: 100% auto;
		}
	}


	@include breakpoint(lg) {
		&:nth-child(1) {
			margin-bottom: 205px;
		}
		&:nth-child(2) {
			margin-bottom: 100px;
		}
		&:nth-child(3) {
			margin-bottom: 100px;
		}
		&:nth-child(4) {
			margin-bottom: 235px;
		}
		&:nth-child(5) {
			margin-bottom: 225px;
		}
		&:nth-child(6) {
			margin-bottom: 95px;
		}
		&:nth-child(7) {
			&.row {
				align-items: flex-start;

			}
			.item-about__main {
				padding-top: 10px;
			}
		}
		&:not(.flex-lg-row-reverse) {
			.item-about {
				&__main {
					text-align: right;
					&.col {
						padding-left: 15px;
						padding-right: 50px;
					}
				}
			}
			ul {
				&>li {
					padding-right: 25px;
					padding-left: 0;
					&:before {
						right: 0;
						left: auto;
					}
				}
			}
		}

	}

	&.row {
		align-items: center;
	}

	.heading {
		margin-bottom: 30px;
		@include breakpointMax(lg) {
			text-align: center;
			
		}
	}


	ul {
		list-style: none;
		font-size: 18px;
		line-height: 21.3px;
		&>li {
			position: relative;
			padding-left: 25px;
			&:not(:last-child) {
				margin-bottom: 15px;
			}
			&:before {
				content: "";
				position: absolute;
				left: 0;
				top: 6px;
				width: 7px;
				height: 7px;
				border-radius: 100%;
			}
		}
	}

	&__main {
		&.col {
			flex-basis: auto;
			
			@include breakpoint(lg) {
				padding-left: 50px;
			}
		}
	}

	&__img {
		display: flex;
		justify-content: center;
		img {
			align-self: center;

			margin-top: 40px;
			
			@include breakpoint(lg) {
				margin-top: 0;

				max-width: 120%;
			}
		}
	}


	
	&.theme {
		&-green {
			.heading {
				color: $green;
			}
			ul {
				&>li {
					&:before {
						background: $green;
					}
				}
			}

		}
		&-yellow {
			.heading {
				color: $yellow;
			}
			ul {
				&>li {
					&:before {
						background: $yellow;
					}
				}
			}
			
		}
		&-blue {
			.heading {
				color: $blue;
			}
			ul {
				&>li {
					&:before {
						background: $blue;
					}
				}
			}
			
		}
	}
}

