//- --------------------------------------------------------------------------
//- Typography
//- --------------------------------------------------------------------------


body {
    font: 400 17px/20px $fontBase;
}


* {
    outline: none;
}


h1, h2, h3, h4, h5, h6 {
    line-height: 1.333;
    font-weight: 400;
    margin: 0 0 20px;
}


p, figure, em, blockquote {
    margin: 0 0 20px;
    display: block;
}


img {
    max-width: 100%;
    max-height: 100%;
    flex-shrink: 0;
}


blockquote {
    display: block;
    background: #E5EDF6;
    border-radius: 3px;


    padding: 20px;
    margin: 0 0 20px;
    position: relative;

    font-size: 14px;
    line-height: 16px;
    font-weight: 500;
    color: #182045;

    &:after {
        content: '';
        display: block;
        position: absolute;
        top: 100%;
        left: 12px;
        border-right: 12px solid transparent;
        border-left: 12px solid transparent;
        border-top: 12px solid #E5EDF6;
    }

    p {
        margin: 0 0 10px;
        &:last-child {
            margin: 0;
        }
    }
}

em {
    display: block;
    font-size: 18px;
    line-height: 1.666;
    font-weight: 400;
    font-style: normal;
}

cite {
    font-size: 14px;
    line-height: 32px;
    font-weight: 400;
    font-style: normal;
}

b, strong {
    font-weight: 700;
}

dl {
    margin: 30px 0;
}

dt {
    display: block;
    margin: 0 0 13px;
    font-size: 18px;
    line-height: 1;
    font-weight: 500;
    color: #000;
}
dd {
    display: block;
    margin: 0 0 20px;
}


hr {
    border: none;
    border-top: 1px solid #ccc;
    margin: 24px 0;
    clear: both;
}



a {
    transition: color 0.25s;
    color: blue;
    text-decoration: none;
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }
}

mark {
    background: none;
    color: #347D3B;
}

video {
    max-width: 100%;
}

textarea {
    resize: none;
    overflow: hidden;
}

.heading {
    font-weight: 500;
    font-size: 30px;
    line-height: 35px;
    @include breakpoint(lg) {
        font-size: 40px;
        line-height: 47px;
    }
}

.heading-about {
    color: $blue;
    font-size: 25px;
    line-height: 30px;
}

.subtitle {
    font-size: 20px;
    line-height: 24px;
    color: $blue;
}

.text-heading {
    font-weight: bold;
    font-size: 30px;
    line-height: 36px;
    @include breakpoint(lg) {
        font-size: 36px;
        line-height: 43px;
    }
}

.text-subheading {
    font-weight: bold;
    margin-bottom: 25px;

    font-size: 24px;
    line-height: 30px;
    @include breakpoint(lg) {
        font-size: 27px;
        line-height: 32px;
    }
}

.text-number {
    color: $blue;
    font-weight: bold;
    font-size: 17px;
    line-height: 20px;
    flex-shrink: 0;
    
    width: 100%;
    margin-bottom: 3px;
    
    @include breakpoint(lg) {
        width: 100px;
        margin-bottom: 0;
    }
}

.form-title {
    font-size: 17px;
    line-height: 20px;
    color: rgba($black, .5);
}

.text-error {
    font-size: 17px;
    line-height: 20px;
    color: $red;
    margin-top: 5px;
}


.heading-main {
    color: $blue;
    font-weight: 500;
    
    font-size: 34px;
    line-height: 40px;

    margin-bottom: 30px;
    
    @include breakpoint(lg) {
        font-size: 50px;
        line-height: 59px;
        margin-bottom: 50px;
    }
}

.modal-heading {
    font-weight: 500;
    font-size: 25px;
    line-height: 30px;
    margin-bottom: 25px;
}

.text-wrapper {
	font-size: 17px;
	line-height: 20px;
	p {
		&:last-child {
			margin-bottom: 20px;
			@include breakpoint(lg) {
				margin-bottom: 35px;
			}
		}
	}
	ul {
		line-height: 24px;
		margin-top: 27px;
		margin-bottom: 30px;
		margin-left: 20px;
		@include breakpoint(lg) {
			padding-left: 130px;
			margin-left: 0;
		}
		li {
			padding-left: 0px;
		}
	}
}


