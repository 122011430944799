$gray: #767676;
$gray2: #777777;
$gray3: #B8B8B8;
$gray-light: #AEAEAE;
$white: #ffffff;
$white-light: #FCFCFC;
$black: #000000;
$lime: #CBD42E;
$blue: #43AADB;
$blue-light: #DBEDF9;
$green: #ACD068;
$yellow: #DEB646;
$red: #DE3D3D;
$pink: #FFE9E1;


.color {
	&-blue {
		color: $blue;
	}
	&-yellow {
		color: $yellow;
	}
	&-green {
		color: $green;
	}
}

