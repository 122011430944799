// --------------------------------------------------------------------------
// Tables
// --------------------------------------------------------------------------

.ui-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    border-radius: 4px;

    text-align: left;

    font-size: 12px;
    line-height: 24px;
    font-weight: 700;


    th, td {
        background: #690909;
        padding: 10px 16px;
        vertical-align: middle;
        border: 1px solid #890E0E;
        border-bottom: none;
        &:not(:first-child) {
            border-left: none;
        }
    }


    th {
        color: #fff;
    }



    tfoot tr:first-child {
        th, td {
            border-top: 8px solid #890E0E;
        }
    }

    tfoot tr:last-child {
        th, td {
            border-bottom: 1px solid #890E0E;
        }
    }

    mark {
        color: #FFC30C;
    }
    

    @include breakpoint(xl) {
        th, td {
            padding: 12px 16px;
        }
    }

}
