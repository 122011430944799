// --------------------------------------------------------------------------
// Testiominals
// --------------------------------------------------------------------------


.testiominals-wrapper {
	.slick-track {
		padding-top: 10px;
		padding-bottom: 20px;
	}

	.slick-list {
	}
}

.testiominal {
	width: 290px;
	background: $white;
	box-shadow: 0px 10px 20px rgba(41, 41, 42, 0.07);
	border-radius: 8px;
	margin-right: 20px;
	padding: 15px;
	@include breakpoint(lg) {
		padding: 30px;
		width: 350px;
	}
	&__logo {
		height: 75px;
		margin-bottom: 20px;
		img {
			align-self: center;
		}
	}
	&__section {
		position: relative;
		@include breakpoint(lg) {
			padding-left: 35px;
		}
		.icon-quotes {
			position: absolute;
			left: 0;
			top: 0;
			fill: $blue-light;
			display: none;
			@include breakpoint(lg) {
				display: block;
			}
		}
	}
	&__text {
		font-size: 16px;
		line-height: 19px;
		margin-bottom: 25px;
	}

	&__name {
		font-weight: bold;
		font-size: 18px;
		line-height: 28px;
		font-family: $fontSecond;
	}
	&__post {
		font-weight: 500;
		font-size: 14px;
		line-height: 24px;
		color: $gray-light;

	}
}


