// --------------------------------------------------------------------------
// Icons
// --------------------------------------------------------------------------

[class^="icon-"], [class*=" icon-"] {
	display: inline-block;
	vertical-align: middle;
	position: relative;
	fill: currentColor;
	fill-rule: evenodd;
	max-height: 100%;
	max-width: 100%;
	flex-shrink: 0;
}

.icon-sign-in {
	width: 22px;
	height: 22px;
}

.icon-menu {
	stroke: $white;
	stroke-width: 2px;
}

.icon-check {
	fill: none;
	stroke: $white;
	stroke-width: 2px;
}
