/* ==========================================================================
   Utilites
   ========================================================================== */

@mixin clearfix {
    &:after {
        content: "";
        display: table;
        clear: both;
    }
}

@mixin list-style-none {
    margin: 0;
    padding: 0;
    list-style: none;
}



@mixin breakpoint($point) {
    @if $point == xs {
        @media (min-width: $xs) { @content ; }
    }
    @if $point == sm {
        @media (min-width: $sm) { @content ; }
    }
    @else if $point == md {
        @media (min-width: $md) { @content ; }
    }
    @else if $point == lg {
        @media (min-width: $lg) { @content ; }
    }
    @else if $point == xl {
        @media (min-width: $xl) { @content ; }
    }
    @else if $point == wide {
        @media (min-width: $wide) { @content ; }
    }
    @else if $point == hd {
        @media (min-width: $hd) { @content ; }
    }
    @else {
        @media (min-width: $point) { @content ; }
    }
}

@mixin breakpointMax($point) {
    @if $point == xs {
        @media (max-width: $xs - 1px) { @content ; }
    }
    @if $point == sm {
        @media (max-width: $sm - 1px) { @content ; }
    }
    @else if $point == md {
        @media (max-width: $md - 1px) { @content ; }
    }
    @else if $point == lg {
        @media (max-width: $lg - 1px) { @content ; }
    }
    @else if $point == xl {
        @media (max-width: $xl - 1px) { @content ; }
    }
    @else if $point == wide {
        @media (max-width: $wide - 1px) { @content ; }
    }
    @else if $point == hd {
        @media (max-width: $hd - 1px) { @content ; }
    }
     @else {
        @media (max-width: $point - 1px) { @content ; }
    }
}