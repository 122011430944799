// .icon {
// 	display: inline-block;
// 	width: 1em;
// 	height: 1em;
// 	fill: currentColor;
// }

.icon-call {
	display: inline-block;
	vertical-align: middle;
	width: 21px;
	height: 21px;
}
.icon-check {
	display: inline-block;
	vertical-align: middle;
	width: 10px;
	height: 8px;
}
.icon-close {
	display: inline-block;
	vertical-align: middle;
	width: 17px;
	height: 18px;
}
.icon-fb {
	display: inline-block;
	vertical-align: middle;
	width: 11px;
	height: 22px;
}
.icon-menu {
	display: inline-block;
	vertical-align: middle;
	width: 22px;
	height: 14px;
}
.icon-quotes {
	display: inline-block;
	vertical-align: middle;
	width: 16px;
	height: 14px;
}
.icon-sign-in {
	display: inline-block;
	vertical-align: middle;
	width: 24px;
	height: 24px;
}
.icon-vk {
	display: inline-block;
	vertical-align: middle;
	width: 25px;
	height: 15px;
}

