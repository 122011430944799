//- --------------------------------------------------------------------------
//- Buttons
//- --------------------------------------------------------------------------

.btn {
	text-decoration: none;
	background: none;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	position: relative;
	transition: .25s;
	text-align: center;
	max-width: 100%;
	cursor: pointer;
	font-family: $fontBase;
	&:hover {
		text-decoration: none;
	}
	&:active {
		transition: none;
	}

	&-main {
		background: $blue;
		color: $white;
		font-weight: 500;
		font-size: 20px;
		line-height: 24px;
		border-radius: 12px;
		
		padding-left: 37px;
		padding-right: 37px;
		
		height: 64px;

		&:hover {
			background: darken($blue, 10%);
		}
		
		svg {
			&:last-child {
				margin-left: 10px;
			}
		}
		&--login {
			padding-left: 60px;
			padding-right: 60px;
		}
		
		&--mini {
			padding-left: 15px;
			padding-right: 15px;
			font-size: 14px;
			height: 42px;
		}

		&--header {
			padding-left: 35px;
			padding-right: 35px;
		}

		&--offsets-medium {
			padding-left: 20px;
			padding-right: 20px;
		}

		&--theme-yellow {
			background: $yellow;
			&:hover {
				background: darken($yellow, 10%);
			}
		}

		&--size-medium {
			height: 52px;
			font-size: 18px;
		}


	}
	
	&-social {
		width: 44px;
		height: 44px;
		border-radius: 9px;
		background: $white;
		&:hover {
			
		background: rgba($white, .7);
		}
		@include breakpoint(lg) {
			width: 36px;
			height: 36px;
		}
		svg {
			fill: $gray;
		}
	}

	&-submit {
		background: $green;
		border: 2px solid $white;
		box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.25);
		border-radius: 12px;
		height: 60px;

		font-weight: 500;
		font-size: 20px;
		line-height: 24px;
		color: $white;
		padding-left: 30px;
		padding-right: 30px;
		.icon-call {
			margin-left: 15px;
		}
		&:hover {
			background: darken($green, 10%);
		}
		&[disabled] {
			border: 0;
			background: #87C4EB;
			box-shadow: none;
			color: rgba($white, .5);
			svg {
				opacity: .8;
			}
		}
	}






	&--fullwidth {
		width: 100%;
	}

}
