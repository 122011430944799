// --------------------------------------------------------------------------
// Standart
// --------------------------------------------------------------------------

.ui-wysiwyg {

	font-size: 12px;
	line-height: 20px;
	font-weight: 400;
	
	p, figure {
		& + h1,
		& + h2,
		& + h3,
		& + h4,
		& + h5,
		& + h6 {
			padding: 20px 0 0;
		}
	}
	

	p, figure {
		margin: 0 0 20px;
		&:last-child {
			margin: 0;
		}
	}
	img {
		display: block;
	}

	a {
		text-decoration: underline;
	}

	ul {
		@extend .ui-ul;
	}

	ol {
		@extend .ui-ol;
	}

	table {
		@extend .ui-table;
	}

	figure {
		@extend .ui-figure;
	}
	
}